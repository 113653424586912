<template>
  <nav id="navheader" class="flex-initial relative bg-teal-200">
    <!-- <div class="flex">
      <div class="flex-initial">
        Menu
      </div>
      <div class="flex-1">
        <p class="text-sm text-center text-yellow-500">TschobRuter</p>
      </div>
      <div class="flex-initial">
        Logout
      </div>
    </div> -->
  </nav>
</template>

<script>
export default {

}
</script>

<style>

</style>