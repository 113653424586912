<template functional>
  <svg
    id="dsg__brand"
    data-name="Gruppe 9"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="400"
    height="21.962"
    viewBox="0 0 400 21.962"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rechteck_14"
          data-name="Rechteck 14"
          width="400"
          height="21.962"
          :fill="props.color"
        ></rect>
      </clipPath>
    </defs>
    <g id="Gruppe_7" data-name="Gruppe 7" clip-path="url(#clip-path)">
      <path
        id="Pfad_1"
        data-name="Pfad 1"
        d="M24.936,14.632c0,4.445-3.974,7.331-11.6,7.331-5.358,0-10.01-1.413-13.336-3.915l3.562-4.122A16.663,16.663,0,0,0,13.454,16.9c2.944,0,4.386-.5,4.386-1.531,0-1-1.177-1.354-5-1.678C6.506,13.13.883,11.688.883,6.977.883,2.473,5.8,0,12.482,0c4.328,0,8.6,1,11.57,2.974l-3.415,4.18A14.452,14.452,0,0,0,12.482,5c-2.09,0-4.5.265-4.5,1.384,0,1,1.855,1.207,5.181,1.472,6.889.559,11.776,1.884,11.776,6.771"
        transform="translate(0 0)"
        :fill="props.color"
      ></path>
      <path
        id="Pfad_2"
        data-name="Pfad 2"
        d="M170.838,13.366l5.917,3.121c-1.855,3.12-6.182,5.476-11.894,5.476-7.8,0-13.218-3.945-13.218-10.981S157.06,0,165.126,0c5.446,0,9.745,2.385,11.54,5.446L170.72,8.538a6.325,6.325,0,0,0-5.682-2.915c-3.651,0-6.124,1.943-6.124,5.358s2.473,5.358,6.124,5.358a6.418,6.418,0,0,0,5.8-2.973"
        transform="translate(-124.764)"
        :fill="props.color"
      ></path>
      <path
        id="Pfad_3"
        data-name="Pfad 3"
        d="M332.61,3.82V24.428h-7.183V16.8h-10.8v7.625h-7.183V3.82h7.183v7.331h10.8V3.82Z"
        transform="translate(-252.945 -3.143)"
        :fill="props.color"
      ></path>
      <path
        id="Pfad_4"
        data-name="Pfad 4"
        d="M498.371,3.82V24.428h-6.3L480.119,12.093V24.428H473.2V3.82h7.6l10.628,11.187V3.82Z"
        transform="translate(-389.326 -3.143)"
        :fill="props.color"
      ></path>
      <path
        id="Pfad_5"
        data-name="Pfad 5"
        d="M661.6,19.247v5.181H638.961V3.821h22.315V9H646v2.62H658.45v4.769H646v2.856Z"
        transform="translate(-525.705 -3.144)"
        :fill="props.color"
      ></path>
      <rect
        id="Rechteck_13"
        data-name="Rechteck 13"
        width="7.183"
        height="20.608"
        transform="translate(139.163 0.677)"
        :fill="props.color"
      ></rect>
      <path
        id="Pfad_6"
        data-name="Pfad 6"
        d="M874.543,14.124c0,6.506-5.122,10.3-13.13,10.3H849.4V3.82h12.011c8.008,0,13.13,3.768,13.13,10.3m-7.331,0c0-3.12-2.443-4.828-6.388-4.828h-4.239v9.656h4.239c3.945,0,6.388-1.707,6.388-4.828"
        transform="translate(-698.845 -3.143)"
        :fill="props.color"
      ></path>
      <path
        id="Pfad_7"
        data-name="Pfad 7"
        d="M1031.822,19.247v5.181h-22.639V3.821H1031.5V9h-15.279v2.62h12.453v4.769h-12.453v2.856Z"
        transform="translate(-830.305 -3.144)"
        :fill="props.color"
      ></path>
      <path
        id="Pfad_8"
        data-name="Pfad 8"
        d="M1172.3,24.429l-5-6.065h-4.828v6.065h-7.125V3.821h14.632c5.505,0,9.862,2.355,9.862,7.272,0,3.386-2.061,5.564-5.152,6.595l5.8,6.742Zm-2.915-10.863c1.855,0,3.179-.53,3.179-2.3s-1.325-2.3-3.179-2.3h-6.918v4.593Z"
        transform="translate(-950.56 -3.144)"
        :fill="props.color"
      ></path>
      <path
        id="Pfad_9"
        data-name="Pfad 9"
        d="M1394.816,16.553c-1.708,3.209-5.829,5.623-11.54,5.623-7.978,0-12.689-4.21-12.689-10.922S1375.3.332,1383.187.332c5.034,0,8.42,1.884,10.481,4.3l-2.149,1.5c-1.855-2.326-4.593-3.5-8.332-3.5-6.153,0-10.068,2.915-10.068,8.626s3.945,8.626,10.245,8.626c4.092,0,7.448-1.207,8.979-4.063V13.138h-9.362v-2.3h11.835Z"
        transform="translate(-1127.65 -0.273)"
        :fill="props.color"
      ></path>
      <path
        id="Pfad_10"
        data-name="Pfad 10"
        d="M1555.818,24.429l-6.83-7.89h-9.8v7.89h-2.5V3.821h13.837c4.593,0,7.89,2.3,7.89,6.359,0,3.592-2.591,5.829-6.388,6.271l7.036,7.978Zm-5.476-10.186c3.327,0,5.535-1.266,5.535-4.063s-2.208-4.063-5.535-4.063h-11.187v8.125Z"
        transform="translate(-1264.305 -3.144)"
        :fill="props.color"
      ></path>
      <path
        id="Pfad_11"
        data-name="Pfad 11"
        d="M1709.748,3.82V14.713c0,6.006-3.768,10.333-10.863,10.333s-10.893-4.328-10.893-10.333V3.82h2.473V14.566c0,5.064,3.032,8.184,8.42,8.184,5.358,0,8.39-3.121,8.39-8.184V3.82Z"
        transform="translate(-1388.795 -3.143)"
        :fill="props.color"
      ></path>
      <path
        id="Pfad_12"
        data-name="Pfad 12"
        d="M1868.612,10.357c0,4.122-3.15,6.536-7.89,6.536h-11.3v7.537h-2.473V3.821h13.778c4.74,0,7.89,2.414,7.89,6.536m-2.532,0c0-2.885-2.178-4.239-5.535-4.239h-11.128V14.6h11.128c3.357,0,5.535-1.354,5.535-4.239"
        transform="translate(-1519.573 -3.144)"
        :fill="props.color"
      ></path>
      <path
        id="Pfad_13"
        data-name="Pfad 13"
        d="M2017.266,10.357c0,4.122-3.15,6.536-7.89,6.536h-11.3v7.537H1995.6V3.821h13.778c4.74,0,7.89,2.414,7.89,6.536m-2.532,0c0-2.885-2.179-4.239-5.535-4.239h-11.128V14.6H2009.2c3.356,0,5.535-1.354,5.535-4.239"
        transform="translate(-1641.878 -3.144)"
        :fill="props.color"
      ></path>
      <path
        id="Pfad_14"
        data-name="Pfad 14"
        d="M2164.182,22.132v2.3h-19.931V3.821h19.636v2.3h-17.163V12.8h14.543v2.3h-14.543v7.036Z"
        transform="translate(-1764.182 -3.143)"
        :fill="props.color"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 21.962,
    },
    color: {
      type: String,
      default: "#fff",
    },
  },
};
</script>

<style>
</style>